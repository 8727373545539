import React from "react";
import { isChrome, isFirefox, isIeEdge, isSafari, getBrowser } from "@tightrope/lpscripts/browserdetect";
import Styles from "./css/gwlp.module.scss";
import Data from "./data/gwlp.json";
import Disclaimers from "@tightrope/disclaimers";

class gwlp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isChrome: isChrome(),
      isFirefox: isFirefox(),
      isIeEdge: isIeEdge(),
      isSafari: isSafari(),
      display: "none",
      pageData: props.data,
      data: Data,
      isLoading: true,
      showSecondStep: false,
      showAlmostThere: false
    };
  }

  showBrowsers(browsers) {
    let display = false;
    if (getBrowser() === "ie" && browsers.includes("ie")) {
      display = true;
    }
    else if (getBrowser() === "firefox" && browsers.includes("firefox")) {
      display = true;
    }
   else if (getBrowser() === "chrome" && browsers.includes("chrome")) {
      display = true;
    }
    else if (getBrowser() === "edge" && browsers.includes("edge")) {
      display = true;
    }
   else if (getBrowser() === "safari" && browsers.includes("safari")) {
      display = true;
    }
    else if (getBrowser() === "other" && browsers.includes("other")) {
      display = true;
    }
    if (display) this.setState({ display: "block" });
    else this.setState({ display: "none" });
  }

  componentDidMount() {
    if (this.props.browsers) {
      this.showBrowsers(this.props.browsers);
    } else {
      this.setState({ display: "block" });
    }
    if (this.props.data) {
      this.props.data.then((data) => {
        const instanceData = Object.assign(this.state.data, data);
        this.setState({ data: instanceData });
        this.state.isChrome = isChrome();
        this.state.isFirefox = isFirefox();
        this.state.isIeEdge = isIeEdge();
        this.state.isSafari = isSafari();
        if (isChrome()) {
          const browserData = Object.assign(this.state.data, this.state.data.chrome);
          this.setState({ data: browserData });
        }
        if (isFirefox()) {
          const browserData = Object.assign(this.state.data, this.state.data.firefox);
          this.setState({ data: browserData });
        }
        if (isSafari()) {
          const browserData = Object.assign(this.state.data, this.state.data.safari);
          this.setState({ data: browserData });
        }
        if (isIeEdge()) {
          const browserData = Object.assign(this.state.data, this.state.data.edge);
          this.setState({ data: browserData });
        }
        if (typeof window != "undefined") {
            window.mapParams();
        }
      });
    }

    window.addEventListener("load", () => {
      this.setState({ isLoading: false });
    });
  }

  startAnimation = () => {
    if (document.getElementById("button").innerHTML === this.state.data.tryAgain) {
      if(typeof window != "undefined") {
        window.triggerInstall();
      }
      return;
    }

    const button = document.getElementById("button");
    button.innerHTML = this.state.data.addToChrome;
    button.style.backgroundColor = "#1a73e8";
    button.style.width = "400px";
    button.style.borderRadius = "10px";
    button.classList.add(Styles.slideDown);

    var stepTrack = document.querySelector(`.${Styles.stepTrack}`);
    stepTrack.classList.add(`${Styles.active}`);

    const triggerInstallWithDelay = () => {
      setTimeout(() => {
        if(typeof window != "undefined") {
          window.triggerInstall();
        }
      }, 1000);

      setTimeout(() => {
        this.setState({ 
          showSecondStep: false,
          showAlmostThere: false,
          data: {
            ...this.state.data,
            headline: this.state.data.headlineAfterClick
          }
        });
        
        const button = document.getElementById("button");
        button.style.backgroundColor = "#218C49";
        button.style.width = "250px";
        button.style.borderRadius = "2px";
        button.innerHTML = this.state.data.tryAgain;
        button.classList.remove(Styles.slideDown);

        const stepContainer = document.querySelector(`.${Styles.stepContainer}`);
        if (stepContainer) {
          stepContainer.style.display = 'none';
        }
      }, 1100);
    };

    this.setState({ 
      showSecondStep: true,
      showAlmostThere: true
    }, () => {
      document.getElementById("button").onclick = triggerInstallWithDelay;
      document.querySelectorAll(`.${Styles.secondStep} span`).forEach(span => {
        span.style.cursor = 'pointer';
        span.onclick = triggerInstallWithDelay;
      });
    });

    var listItems = document.querySelectorAll(`.${Styles.stepContainer} ul li`);
    
    if (listItems.length >= 1) {
      listItems[0].classList.add(`${Styles.stepComplete}`);
      listItems[0].classList.remove(`${Styles.isActive}`);
    }

    if (listItems.length >= 2) {
      listItems[1].classList.add(`${Styles.isActive}`);
      listItems[1].classList.remove(`${Styles.secondStepActive}`);
    }

    if(navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1)
      return;
  }

  render() {
    return (
      <>
        <div id={Styles.gwlpPs}>
          <div className={`${Styles.preloader} ${this.state.isLoading ? Styles.isLoading : ""}`}>
            <div className={Styles.loader}>
              <div className={Styles.ball}></div>
              <div className={Styles.ball}></div>
              <div className={Styles.ball}></div>
              <div className={Styles.ball}></div>
              <div className={Styles.shadow}></div>
              <div className={Styles.shadow}></div>
              <div className={Styles.shadow}></div>
              <div className={Styles.shadow}></div>
            </div>
          </div>
          <main>
            <section>
              <h1 className="hc_param">{this.state.data.headline}</h1>
              <div className={Styles.logo}>
                <img className={Styles.ablogo} alt="Better Search Logo" src={this.state.data.ablogo} />
              </div>
              <h2 className={Styles.subList}>{this.state.data.content}</h2>
              <div className={Styles.compatible}>
                <img alt="checkbox" className={Styles.checkBox} src={this.state.data.checkIcon} />
                <h3>{this.state.data.compatible}</h3>
                <div className={Styles.tooltipWrapper}>
                  <img className={Styles.cLogo} alt="Chrome Web Store" src={this.state.data.cwsBadge} />
                  <div className={Styles.tooltip}>
                    <div className={Styles.tooltipHeader}>{this.state.data.tooltip.header}</div>
                    <ul>
                      {this.state.data.tooltip.items.map((item, index) => (
                        <li key={index}>• {item}</li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
              <div 
                id="second-step" 
                className={Styles.secondStep}
                style={{ display: this.state.showSecondStep ? 'block' : 'none' }}
              >
                <h3>{this.state.data.secondStep.title}</h3>
                <h4>{this.state.data.secondStep.subtitle}</h4>
                <ol>
                  {this.state.data.secondStep.steps.map((step, index) => (
                    <li key={index}>
                      {step.number}. {step.text}
                      <span 
                        className={Styles.actionLink}
                        onClick={this.startAnimation}
                      >
                        {step.action}
                      </span>
                      {step.suffix}
                    </li>
                  ))}
                </ol>
              </div>
              <button 
                id="button"
                onClick={this.startAnimation}
                className = "cl1"
              >
                {this.state.data.button}
              </button>
              {this.props.children}
              {this.state.showSecondStep && <Disclaimers ></Disclaimers>}
              <div className={Styles.stepContainer}>
                <div className={Styles.stepTrack}>
                  <div className={Styles.blueLine}></div>
                </div>
                <ul>
                  <li className={Styles.isActive}>{this.state.data.stepTracker.step1}</li>
                  <li>{this.state.data.stepTracker.step2}</li>
                  <li>{this.state.data.stepTracker.step3}</li>
                </ul>
                {this.state.showAlmostThere && (
                  <div className={Styles.almostThere}>{this.state.data.stepTracker.almostThere}</div>
                )}
              </div>
            </section>
          </main>
        </div>
      </>
    );
  }
}

export default gwlp;
